import {extendTheme} from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    blackGold: {
      900: '#1a202c', // Very dark blackish color, similar to Chakra's gray.900
      500: '#ffd700', // Gold color
    },
  },
  fonts: {
    heading: 'Lato',
    body: 'Lato',
  },
  breakpoints: {
    'sm': '30em', // 480px
    'md': '48em', // 768px
    'lg': '62em', // 992px
    'xl': '80em', // 1280px
    '2xl': '96em', // 1536px
  },
  styles: {
    global: {
      body: {
        overflowX: 'hidden',
        minHeight: '100vh', // Ensure the body always fills the viewport
        backgroundColor: 'blackGold.900',
        color: 'blackGold.500',
      },
    },
  },
});

export default theme;
