import React from 'react';
import {Image} from '@chakra-ui/react';
import HFFLogo from '../../assets/HFFLogo.png';

const HawkinsFamilyFindsLogo = () => (
  <Image src={HFFLogo} alt="Pothos Logo White" width={100} borderRadius="50%" />
);

const PothosLogoWhite = () => (
  <Image
    src={HFFLogo}
    alt="Pothos Logo Transparent"
    width={100}
    borderRadius="50%"
  />
);

export {PothosLogoWhite, HawkinsFamilyFindsLogo};
