import React from 'react';
import {
  Box,
  Text,
  Heading,
  VStack,
  Link,
  Divider,
  useColorModeValue,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import {
  buyerProcessData,
  sellerProcessData,
} from './constants';

const LandProcess = () => {
  const dividerColor = useColorModeValue('gray.400', 'gray.600');
  console.log(buyerProcessData, 'buyerProcessData');
  return (
    <>
      <Divider borderColor={dividerColor} w="80%" />

      <Heading as="h2" size="lg" textAlign="center" mb={5}>
        Process
      </Heading>
      <Accordion allowToggle w="80%" margin="auto">
        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              <Heading as="h3" size="md">
                Sellers{' '}
              </Heading>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Heading as="h3" size="md">
              {' '}
              If you're considering selling land, here's what the process looks
              like with Hawkins Family Finds:{' '}
            </Heading>
            {sellerProcessData.map((item, index) => (
              <VStack align="start" spacing={3} mt={3} key={index}>
                <Heading as="h3" size="sm" textAlign="left">
                  {' '}
                  {item.title}{' '}
                </Heading>
                <Text>{item.content}</Text>
              </VStack>
            ))}
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              <Heading as="h3" size="md">
                Buyers
              </Heading>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Heading as="h3" size="md">
              {' '}
              For those interested in buying land through Hawkins Family Finds,
              here's a glimpse into our streamlined process:
            </Heading>
            {buyerProcessData.map((item, index) => (
              <VStack align="start" spacing={3} mt={3}>
                <Heading as="h3" size="sm" textAlign="left">
                  {' '}
                  {item.title}{' '}
                </Heading>
                <Text>{item.content}</Text>
              </VStack>
            ))}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <Link
        href="/contact"
        _hover={{textDecoration: 'none', transform: 'scale(1.05)'}}
        transition="transform 0.2s ease-in-out"
      >
        <Text fontSize="lg" mt={5} textAlign="center" w="100%">
          Let us help you with your land wholesaling endeavors ❤️
        </Text>
      </Link>
    </>
  );
};

export default LandProcess;
