import React from 'react';
import {
  Box,
  Heading,
  Text,
  Image,
  Flex,
  useColorModeValue,
} from '@chakra-ui/react';
import {motion} from 'framer-motion';
import HFFLogo from '../../assets/HFFLogo.png';
import {aboutUsContent} from './constants';

const MotionBox = motion(Box);
const MotionImage = motion(Image);
const MotionText = motion(Text);
const MotionFlex = motion(Flex);

const fadeIn = {
  initial: {opacity: 0},
  animate: {opacity: 1, transition: {duration: 1.2}},
};

const AboutUs = () => {
  const boxShadow = useColorModeValue('xl', 'dark-lg');

  return (
    <MotionBox
      pt={150}
      pb={100}
      px={10}
      backgroundColor="gray.50"
      color="gray.700"
      maxWidth="1000px"
      margin="auto"
      borderRadius="lg"
      boxShadow={boxShadow}
      variants={fadeIn}
      initial="initial"
      animate="animate"
    >
      <MotionFlex
        minH="100vh"
        direction="column"
        align="center"
        justify="start" // aligned to top
      >
        <MotionImage
          src={HFFLogo}
          alt="HFF Logo"
          width={{base: '150px', md: '250px', lg: '300px'}}
          borderRadius="50%"
          mx="auto"
          my={4}
          display="block"
        />

        <Heading
          mb={6}
          fontSize={{base: '2xl', md: '3xl', lg: '4xl'}}
          fontWeight="bold"
          textAlign="center"
        >
          About Us
        </Heading>

        <MotionBox w={{base: 'full', md: '80%', lg: '70%'}} mt={10}>
          {aboutUsContent.map((content, index) => (
            <MotionText
              key={index}
              fontSize={{base: 'md', md: 'lg', lg: 'xl'}}
              mb={4}
              textAlign="justify" // makes it read like an article
              lineHeight="1.6" // makes it more readable
            >
              {content.text}
            </MotionText>
          ))}
        </MotionBox>
      </MotionFlex>
    </MotionBox>
  );
};

export default AboutUs;
