import React from 'react';
import {
  Box,
  Text,
  Heading,
  VStack,
  Link,
  Divider,
  useColorModeValue,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import {foreclosureProcessData} from './constants';

const ForeclosureProcess = () => {
  const dividerColor = useColorModeValue('gray.400', 'gray.600');
  return (
    <>
      <Divider borderColor={dividerColor} w="80%" />

      <Heading as="h2" size="lg" textAlign="center" mb={5}>
        Process
      </Heading>
      <Accordion allowToggle w="80%" margin="auto">
        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              <Heading as="h3" size="md">
                {' '}
                Steps
              </Heading>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Heading as="h3" size="md">
              {' '}
              If you're considering selling land, here's what the process looks
              like with Hawkins Family Finds:{' '}
            </Heading>
            {foreclosureProcessData.map((item, index) => (
              <VStack align="start" spacing={3} mt={3}>
                <Heading as="h3" size="sm" textAlign="left">
                  {' '}
                  {item.title}{' '}
                </Heading>
                <Text>{item.content}</Text>
              </VStack>
            ))}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <Link
        href="/contact"
        _hover={{textDecoration: 'none', transform: 'scale(1.05)'}}
        transition="transform 0.2s ease-in-out"
      >
        <Text fontSize="lg" mt={5} textAlign="center" w="100%">
          Let us help you reclaim your funds ❤️
        </Text>
      </Link>
    </>
  );
};

export default ForeclosureProcess;
