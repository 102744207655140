import React from 'react';
import {
  Box,
  Container,
  Stack,
  Text,
  useColorModeValue,
  Flex,
  Link,
} from '@chakra-ui/react';
import {PothosLogoWhite} from './PothosLogo';

export default function LargeWithLogoLeft() {
  const footerLinks = [
    {
      name: 'Contracts',
      href: '/contracts',
    },
    {
      name: 'About Us',
      href: '/about-us',
    },
    {
      name: 'Contact',
      href: '/contact',
    },
    {
      name: 'Terms',
      href: '/terms',
    },
  ];
  return (
    <Box color={useColorModeValue('gray.700', 'gray.200')}>
      <Container as={Stack} maxW={'6xl'} py={10}>
        <Flex
          direction={{base: 'column', md: 'row'}} // Stack vertically on mobile, horizontally on desktop
          justifyContent="space-between"
          alignItems="center"
          spacing={4} // Adjust spacing as needed
        >
          <Stack spacing={6} direction={{base: 'column', md: 'row'}}>
            <Box>
              <PothosLogoWhite />
              <Text fontSize={{base: 'sm', md: 'md'}} color="white">
                © 2023 Hawkins Family Finds All rights reserved
              </Text>
            </Box>
          </Stack>

          <Flex>
            <Stack spacing={2}>
              {footerLinks.map((link, i) => (
                <Link
                  key={i}
                  href={link.href}
                  _hover={{textDecoration: 'none', transform: 'scale(1.05)'}}
                  transition="transform 0.2s ease-in-out"
                >
                  <Text
                    fontSize={{base: 'sm', md: 'md'}}
                    fontWeight={600}
                    color="white"
                    textAlign="right"
                    _hover={{
                      textDecoration: 'none',
                      color: 'white',
                    }}
                  >
                    {link.name}
                  </Text>
                </Link>
              ))}
            </Stack>
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
}
