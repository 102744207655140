export const aboutUsContent = [
  {
    text: 'Welcome to Hawkins Family Finds, a company forged from love, remembrance, and an enduring spirit of giving. Our journey began with a vision to continue the altruistic legacy of a remarkable man – my father. The company is a tribute to him, embodying his immense heart, boundless generosity, and the countless lives he touched with his kindness and warmth.',
  },
  {
    text: 'My father’s compassion and dedication to aiding others inspired me to create a platform that could truly make a difference. It was his spirit of benevolence that ignited a passion within me to venture into land wholesaling and foreclosure excess funds. These avenues not only allow us to uphold his values but also enable us to assist individuals in navigating through financial intricacies.',
  },
  {
    text: 'At Hawkins Family Finds, our endeavor in land wholesaling is more than a business pursuit; it is a commitment to revitalizing communities and fostering opportunities. We specialize in acquiring properties and connecting them with investors or individuals seeking to cultivate their visions.',
  },
  {
    text: 'The realm of foreclosure excess funds is another avenue through which we aspire to bring hope and relief. We understand the complexities and emotional turmoil that arise from foreclosures. Our mission is to guide and support individuals in reclaiming excess funds they are rightfully entitled to after a foreclosure sale.',
  },
  {
    text: 'Our mission at Hawkins Family Finds is to continue the legacy of my father by empowering individuals, revitalizing communities, and sowing seeds of hope and change. We are dedicated to establishing a network of support, understanding, and mutual growth, where every interaction is imbued with integrity, respect, and a shared desire to make a positive impact.',
  },
];
