import React, {useState, useEffect} from 'react';
import {
  Box,
  Text,
  Heading,
  VStack,
  Link,
  Image,
  Divider,
  Flex,
  useColorModeValue,
} from '@chakra-ui/react';
import {motion} from 'framer-motion';
import foreclosureArticleData from './constants';
import ForeclosureProcess from './ForeclosureProcess';

const MotionBox = motion(Box);
const MotionImage = motion(Image);
const MotionText = motion(Text);
const MotionVStack = motion(VStack);

const fadeIn = {
  initial: {opacity: 0},
  animate: {opacity: 1, transition: {duration: 1.2}},
};

const Foreclosure = () => {
  const boxShadow = useColorModeValue('xl', 'dark-lg');
  const dividerColor = useColorModeValue('gray.400', 'gray.600');

  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollFadeIn = {
    hidden: {opacity: 0},
    show: (i) => ({
      opacity: 1,
      transition: {delay: i * 0.2, duration: 1},
      y: 0,
    }),
    initial: {opacity: 0, y: 100},
  };

  return (
    <MotionBox
      pt={150}
      pb={100}
      px={10}
      backgroundColor="gray.50"
      color="gray.700"
      maxWidth="1000px"
      margin="auto"
      borderRadius="lg"
      boxShadow={boxShadow}
      variants={fadeIn}
      initial="initial"
      animate="animate"
    >
      <MotionVStack spacing={8} alignItems="center">
        <Heading as="h1" size="2xl">
          Foreclosure Surplus Funds: <br />
          The Rights of Homeowners and The Role of Third-Party Agencies
        </Heading>

        <Text fontSize="lg" textAlign="center" w="80%">
          The foreclosure process can be overwhelming for homeowners
        </Text>

        <MotionVStack spacing={10} alignItems="start" w="80%">
          {foreclosureArticleData.map((section, index) => (
            <MotionBox key={section.title} variants={fadeIn} width="100%">
              <Flex justifyContent="center" width="100%">
                <MotionImage
                  w={['100%', '85%', '75%']}
                  src={section.img}
                  alt={section.title}
                  boxShadow="lg"
                  mb={4}
                  borderRadius="md"
                  variants={scrollFadeIn}
                  initial="initial"
                  custom={index}
                  animate={scrollY > index * 150 ? 'show' : 'hidden'}
                />
              </Flex>
              <MotionText fontWeight="bold" fontSize="xl">
                {section.title}:
              </MotionText>
              <MotionText textAlign="justify">{section.content}</MotionText>
            </MotionBox>
          ))}
        </MotionVStack>

        <ForeclosureProcess />
        <Divider borderColor={dividerColor} w="80%" my={5} />
        <Flex justify="space-between" width="80%">
          <Text fontSize="sm" color="gray.500">
            © 2023 by Kristha Hawkins
          </Text>
          <Link
            href="#"
            fontSize="sm"
            color="blue.500"
            _hover={{textDecoration: 'none', transform: 'scale(1.05)'}}
            transition="transform 0.2s ease-in-out"
          >
            Back to top
          </Link>
        </Flex>
      </MotionVStack>
    </MotionBox>
  );
};

export default Foreclosure;
