import HFFImage1 from '../../../assets/HFFImage1.jpg';
import HFFImage2 from '../../../assets/HFFImage2.jpg';
import HFFImage3 from '../../../assets/HFFImage3.jpg';
import HFFImage4 from '../../../assets/HFFImage4.jpg';

export const imagesData = [
  {
    image: HFFImage1,
    header: 'Discover the Potential with Hawkins Family Finds',
    subtext:
      'Hawkins Family isn\'t just our name. It signifies trust, loyalty, and commitment. Experience the family touch in every deal.',
    buttonText: 'Work With Us',
    buttonLink: '/contact',
  },
  {
    image: HFFImage4,
    header: 'Rooted in Family Values',
    subtext:
      'Hawkins Family isn\'t just our name. It signifies trust, loyalty, and commitment. Experience the family touch in every deal.',
    buttonText: 'About Us',
    buttonLink: '/about-us',
  },
  {
    image: HFFImage2,
    header: 'Foreclosure Excess Funds Retrieve What\'s Rightfully Yours',
    subtext:
      'Navigating the complexities of foreclosure excess funds? With our proven expertise and commitment, trust Hawkins Family to help you reclaim every cent.',
    buttonText: 'Discover How We Can Help',
    buttonLink: '/foreclosure-excess-funds',
  },

  {
    image: HFFImage3,
    header: 'Rapid Land Wholesaling Swift and Efficient',
    subtext:
      'In the realm of land wholesaling, we understand the essence of time. With Hawkins Family, you\'re guaranteed a quick and impressive closing process.',
    buttonText: 'Explore Our Quick Turnaround',
    buttonLink: '/land',
  },
];
