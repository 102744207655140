import React from 'react';
import {
  Box,
  Text,
  Heading,
  VStack,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  Accordion,
  Link,
  useColorModeValue,
  Flex,
  Divider,
} from '@chakra-ui/react';
import {motion} from 'framer-motion';
import {contractTransparencyPoints} from './constants';
import sellerContract from '../../assets/Seller_Contract.pdf';
import newAssignmentOfContract from '../../assets/New_Assignment_of_Contract.pdf';
import foreclosureContract from '../../assets/Foreclosure_Excess_Funds_Claimant_Contract.pdf';

const landContracts = [
  {
    title: 'Seller Contract',
    downloadLink: sellerContract,
  },
  {
    title: 'New Assignment of Contract',
    downloadLink: newAssignmentOfContract,
  },
];

const foreclosureContracts = [
  {
    title: 'Foreclosure Excess Funds Claimant Contract',
    downloadLink: foreclosureContract,
  },
];

const MotionBox = motion(Box);
const MotionText = motion(Text);
const MotionVStack = motion(VStack);

const fadeIn = {
  initial: {opacity: 0},
  animate: {opacity: 1, transition: {duration: 1.2}},
};

function downloadFile(url, fileName) {
  fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => console.error('File download error:', error));
}

const ContractsAccordion = ({title, contracts}) => {
  return (
    <AccordionItem>
      <AccordionButton>
        <Box flex="1" textAlign="left">
          <Text fontWeight="bold" fontSize="xl">
            {title}
          </Text>
        </Box>
      </AccordionButton>
      <AccordionPanel>
        {contracts.map((contract) => (
          <MotionText textAlign="justify" key={contract.title}>
            <Link
              onClick={() =>
                downloadFile(contract.downloadLink, contract.title + '.pdf')
              }
            >
              {contract.title} - Download PDF
            </Link>
          </MotionText>
        ))}
      </AccordionPanel>
    </AccordionItem>
  );
};

const Contracts = () => {
  const boxShadow = useColorModeValue('xl', 'dark-lg');
  const dividerColor = useColorModeValue('gray.400', 'gray.600');


  return (
    <MotionBox
      pt={150}
      pb={100}
      px={10}
      backgroundColor="gray.50"
      color="gray.700"
      maxWidth="1000px"
      margin="auto"
      borderRadius="lg"
      boxShadow={boxShadow}
      variants={fadeIn}
      initial="initial"
      animate="animate"
    >
      <MotionVStack spacing={8} alignItems="center">
        <Heading as="h1" size="2xl">
          The Imperative of Transparency in Contracts: Crafting Win-Win Deals
        </Heading>

        <MotionVStack spacing={10} alignItems="start" w="80%">
          {contractTransparencyPoints.map((section, index) => (
            <MotionBox key={section.title} variants={fadeIn} width="100%">
              <Flex justifyContent="center" width="100%"></Flex>
              <MotionText fontWeight="bold" fontSize="xl">
                {section.title}:
              </MotionText>
              <MotionText textAlign="justify">{section.content}</MotionText>
            </MotionBox>
          ))}
        </MotionVStack>

        <Accordion w="80%" allowToggle>
          <ContractsAccordion
            title="Land Contracts"
            contracts={landContracts}
          />
          <ContractsAccordion
            title="Foreclosure Contracts"
            contracts={foreclosureContracts}
          />
        </Accordion>

        <Divider borderColor={dividerColor} w="80%" my={5} />

        <Flex justify="space-between" width="80%">
          <Text fontSize="sm" color="gray.500">
            © 2023 by Kristha Hawkins
          </Text>
          <Link
            href="#"
            fontSize="sm"
            color="blue.500"
            _hover={{textDecoration: 'none', transform: 'scale(1.05)'}}
            transition="transform 0.2s ease-in-out"
          >
            Back to top
          </Link>
        </Flex>
      </MotionVStack>
    </MotionBox>
  );
};

export default Contracts;
