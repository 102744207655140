export const landWholesalingData = [
  {
    title: 'The Evolution of Land Transactions',
    img: 'https://source.unsplash.com/random/800x400?history-land',
    content:
      'The story of land transcends centuries - from bartering in ancient civilizations to today\'s sophisticated digital marketplaces. As times evolved, so did the methodologies of land transactions. Enter land wholesaling, an innovative method bringing efficiency to this age-old practice.',
  },
  {
    title: 'Demystifying Land Wholesaling',
    img: 'https://source.unsplash.com/random/800x400?construction',
    content:
      'Unlike traditional real estate investments, land wholesaling provides an opportunity to profit from land without large capital outlays. It’s about leveraging market knowledge, connecting sellers with potential buyers, and adding value through efficient deal-making.',
  },
  {
    title: 'Benefits for Sellers: Why Consider Wholesaling Your Land?',
    img: 'https://source.unsplash.com/random/800x400?seller',
    content:
      'For sellers, land wholesaling promises a swift transaction, eliminating the long waiting times typical of traditional sales. It offers a hassle-free selling process, reduced paperwork, and the assurance of dealing with committed buyers, ensuring you get value for your land.',
  },
  {
    title: 'Benefits for Buyers: The Advantages of Purchasing Wholesale Land',
    img: 'https://source.unsplash.com/random/800x400?buyer',
    content:
      'For buyers, land wholesaling presents a curated collection of prime lands at competitive prices. It takes away the tedious process of land scouting, offers clear titles, and ensures a faster closing process. It\'s an efficient gateway to premium properties.',
  },
  {
    title: 'Navigating the Landscape: Challenges and Solutions',
    img: 'https://source.unsplash.com/random/800x400?pitfalls',
    content:
      'While land wholesaling offers immense benefits, it\'s not without challenges. Unclear land titles, zoning issues, or market fluctuations can be hurdles. However, with the right partner, these challenges transform into seamless experiences.',
  },
  {
    title: 'Leveraging Modern Tools for Land Wholesaling Success',
    img: 'https://source.unsplash.com/random/800x400?technology',
    content:
      'The integration of technology in land wholesaling has been a game-changer. Digital listings, virtual tours, analytics-driven scouting, and efficient CRM systems have streamlined the process, ensuring deals are faster, transparent, and profitable.',
  },
  {
    title: 'Triumphs in Land Wholesaling: Tales of Success',
    img: 'https://source.unsplash.com/random/800x400?success',
    content:
      'The journey from novice to a successful land wholesaler is filled with learning, challenges, and victories. Many have treaded this path, transforming challenges into success stories, offering inspiration and insights to newcomers.',
  },
  {
    title: 'Why Choose Hawkins Family Finds for Your Land Wholesaling Needs?',
    img: 'https://source.unsplash.com/random/800x400?family',
    content:
      'Your venture into land wholesaling deserves a trusted partner, and that\'s where Hawkins Family Finds steps in. With our extensive industry experience, vast network, and commitment to transparency, we ensure every deal is a win-win. Sellers benefit from swift, fair deals while buyers gain access to prime properties. Join hands with us, and experience land wholesaling that\'s efficient, profitable, and tailored for success.',
  },
];

export const buyerProcessData = [
  {
    title: '1. Discover',
    content: 'Ask us about our curated list of available properties.',
  },
  {
    title: '2. Choose',
    content: 'Select a parcel of land that aligns with your goals.',
  },
  {
    title: '3. Secure the Deal',
    content: 'Sign the purchase agreement to lock in your land.',
  },
  {
    title: '4. Title and Deed',
    content:
      'We work diligently to ensure a seamless title transfer process. Our assessment fee, which covers the expenses incurred during the evaluation of the property, is managed directly by the title company. This way, you can be confident that all financial matters are handled transparently and professionally, free from any complications.',
  },
  {
    title: '5. Finalize the Purchase',
    content: 'Close the deal and begin your land ownership journey.',
  },
];

export const sellerProcessData = [
  {
    title: '1. Initial Consultation',
    content: 'Understand your needs and expectations.',
  },
  {
    title: '2. Property Evaluation',
    content:
      'Check for qualifications including free and clear land, ensuring it\'s not in wetland or flood zones.',
  },
  {
    title: '3. Offer Presentation',
    content:
      'If the land fits our criteria, we present you with a fair cash offer.',
  },
  {
    title: '4. Contract Signing',
    content: 'Agree on terms and sign the sales agreement.',
  },
  {
    title: '5. Title and Deed',
    content:
      'We handle all title work to ensure it\'s free of any liens or encumbrances.',
  },
  {
    title: '6. Close the Sale',
    content: 'Swift closings so you can have cash in hand without any hassles.',
  },
];

export const landProcessData = [
  {
    title: '1. Initial Consultation',
    content: 'Understand your needs and expectations.',
  },
  {
    title: '2. Property Evaluation',
    content:
      'Check for qualifications including free and clear land, ensuring it\'s not in wetland or flood zones.',
  },
  {
    title: '3. Offer Presentation',
    content:
      'If the land fits our criteria, we present you with a fair cash offer.',
  },
  {
    title: '4. Contract Signing',
    content: 'Agree on terms and sign the sales agreement.',
  },
  {
    title: '5. Title and Deed',
    content:
      'We manage all aspects of the title work, ensuring it\'s devoid of any liens or encumbrances. Our assessment fee, vital for evaluating the property\'s suitability, is professionally processed by the title company, ensuring transparency and minimizing any potential complications for our clients.',
  },
  {
    title: '6. Close the Sale',
    content: 'Swift closings so you can have cash in hand without any hassles.',
  },
];

export default landWholesalingData;
