import React, {useState} from 'react';
import {Box, Input, Button, Text, VStack, Heading} from '@chakra-ui/react';

const OptOut = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const baseURL = process.env.NODE_ENV === 'development'
  ? 'http://localhost:5001'
  : '/.netlify/functions';
  const handleSubmit = async () => {
    try {
      const response = await fetch(`${baseURL}/opt-out`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ phoneNumber })
      });
      const data = await response.json();

      if (data.success) {
        setSubmitted(true);
      } else {
        throw new Error(data.message);
      }
    } catch (err) {
      console.error("Error opting out:", err.message);
      setError("Failed to opt-out. Please try again.");
    }
  };

  const optOutText = `Thank you! You've successfully opted out from receiving further messages.`;

  return (
    <Box
      width="100%"
      padding="4rem"
      paddingTop="20rem"
      textAlign="center"
      minHeight="calc(100vh - 8rem)"
    >
      {' '}
      <VStack spacing={4}>
        <Heading marginBottom="2rem">Opt Out of SMS Messages</Heading>
        {submitted ? (
          <Text>
            {optOutText}
          </Text>
        ) : (
          <>
            <Text>
              Please enter your phone number to stop receiving messages.
            </Text>
            {error && <Text color="red.500">{error}</Text>}
            <Input
              type="tel"
              placeholder="Enter your phone number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              maxWidth="30rem"
            />
            <Button onClick={handleSubmit} colorScheme="blue">
              Opt Out
            </Button>
          </>
        )}
      </VStack>
    </Box>
  );
};

export default OptOut;
