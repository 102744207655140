'use client';
import React, {useState} from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Textarea,
  useColorModeValue,
  useToast,
  VStack,
} from '@chakra-ui/react';
import {MdOutlineEmail} from 'react-icons/md';
import emailjs from 'emailjs-com';

export default function ContactFormWithSocialButtons() {
  const toast = useToast();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const {name, value} = e.target;
    setFormData((prev) => ({...prev, [name]: value}));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const serviceId = process.env.REACT_APP_EMAIL_JS_SERVICE_ID;
    const userId = process.env.REACT_APP_EMAIL_JS_USER_ID;
    const templateId = process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID;
  
    emailjs.sendForm(serviceId, templateId, e.target, userId).then(
        (result) => {
          console.log(result.text);
          toast({
            title: 'Message Sent!',
            status: 'success',
            duration: 5000,
            isClosable: true,
            position: 'top-right',
          });
          setFormData({
            name: '',
            email: '',
            message: '',
          });
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
          toast({
            title: 'Failed to send message.',
            description: 'Please try again later.',
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top-right',
          });
        },
    );
  };

  return (
    <Flex
      align="center"
      justify="center"
      mt={{base: '6', md: '12', lg: '16'}}
      id="contact"
    >
      <Box
        borderRadius="lg"
        m={{base: 5, md: 16, lg: 10}}
        p={{base: 5, lg: 16}}
      >
        <Box>
          <VStack spacing={{base: 4, md: 8, lg: 20}}>
            <Heading
              fontSize={{
                base: '4xl',
                md: '5xl',
              }}
            >
              Get in Touch
            </Heading>

            <Stack
              spacing={{base: 4, md: 8, lg: 20}}
              direction={{base: 'column', md: 'row'}}
            >
              <Box
                width="30rem"
                bg={useColorModeValue('white', 'gray.700')}
                borderRadius="lg"
                p={8}
                color={useColorModeValue('gray.700', 'whiteAlpha.900')}
                shadow="base"
              >
                <VStack spacing={5} as="form" onSubmit={handleSubmit}>
                  <FormControl isRequired>
                    <FormLabel>Name</FormLabel>

                    <InputGroup>
                      <InputLeftElement>{/* <BsPerson /> */}</InputLeftElement>
                      <Input
                        type="text"
                        name="name"
                        placeholder="Your Name"
                        value={formData.name}
                        onChange={handleChange}
                      />
                    </InputGroup>
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel>Email</FormLabel>

                    <InputGroup>
                      <InputLeftElement>
                        <MdOutlineEmail />
                      </InputLeftElement>
                      <Input
                        type="email"
                        name="email"
                        placeholder="Your Email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </InputGroup>
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel>Message</FormLabel>

                    <Textarea
                      name="message"
                      placeholder="Your Message"
                      rows={6}
                      resize="none"
                      value={formData.message}
                      onChange={handleChange}
                    />
                  </FormControl>

                  <Button
                    colorScheme="blue"
                    bg="blue.400"
                    color="white"
                    _hover={{
                      bg: 'blue.500',
                    }}
                    width="full"
                    type="submit"
                  >
                    Send Message
                  </Button>
                </VStack>
              </Box>
            </Stack>
          </VStack>
        </Box>
      </Box>
    </Flex>
  );
}
