import React, {useState} from 'react';
import {Routes, Route, useLocation} from 'react-router-dom';
import Home from './components/Home/Home';
import Land from './components/Land/Land';
import Foreclosure from './components/Foreclosure/Foreclosure';
import Contact from './components/Contact/Contact';
import Header from './components/Layout/Header';
import Footer from './components/Layout/Footer';
import About from './components/About/About';
import Contracts from './components/Contracts/Contracts';
import Terms from './components/Terms/Terms';
import OptOut from './components/OptOut/OptOut';
function App() {
  const [bgColor, setBgColor] = useState(
      'linear-gradient(to bottom, black, #201903)',
  ); // Set default gradient
  const location = useLocation();

  return (
    <>
      <Header bgColor={bgColor} location={location} />
      <Routes>
        <Route
          exact
          path="/"
          element={<Home setBgColor={setBgColor} bgColor={bgColor} />}
        />
        <Route
          exact
          path="/foreclosure-excess-funds"
          element={<Foreclosure />}
        />
        <Route exact path="/land" element={<Land />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/about-us" element={<About />} />
        <Route exact path="/terms" element={<Terms />} />
        <Route exact path="/contracts" element={<Contracts />} />
        <Route exact path="/opt-out" element={<OptOut />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
